import config from "../config/site";

const GET_DATA = async (properties) => {
  const result = await fetch(
    config.backend_url +
      "/api/" +
      properties.endpoint +
      "?" +
      new URLSearchParams(properties.params),
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    },
  );

  return result.json();
};

const POST_DATA = (properties) => {
  return fetch(config.backend_url + "/api/" + properties.endpoint, {
    method: "POST",
    body: JSON.stringify(properties.body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "x-recaptcha-token": properties.recaptchaToken,
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export default GET_DATA;
export { POST_DATA };
