import "./App.css";
import Root from "./components/Root";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ArtistDetailPage from "./components/Pages/ArtistDetailPage";
import { QueryClient, QueryClientProvider } from "react-query";
import routes from "./config/routes";
import config from "./config/site";
import EventDetailPage from "./components/Pages/EventDetailPage";

let domainParts = window.location.hostname.split(".");
let subdomain = domainParts.length === 3 ? domainParts[0] : null;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000000 } },
});

const artistRouter = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <ArtistDetailPage artistId={subdomain} /> },
      {
        path: "/event/:eventId",
        element: <EventDetailPage />,
      },
    ],
  },
]);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: routes,
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <link rel="preconnect" href={config.backend_url} />
      <link
        rel="preconnect"
        href={
          "https://www.google.com/recaptcha/api.js?render=" +
          config.recaptcha.site_key
        }
      />
      {subdomain === null && <RouterProvider router={router} />}
      {domainParts.length === 3 && <RouterProvider router={artistRouter} />}
    </QueryClientProvider>
  );
};

export default App;
