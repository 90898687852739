import GET_DATA from "../util/dataRequest";

export const getEventsInfo = async (page, pageSize, onlyUpcoming, artistId) => {
  let params = {
    endpoint: "events",
    params: {
      populate: [
        "name",
        "url",
        "location",
        "coverArtwork",
        "labels",
        "eventDate",
        "artist",
        "artists",
        "selfHosted",
        "organizer",
      ],
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
    },
  };

  if (artistId) {
    params.params["filters[artists][url][$contains]"] = artistId;
  }

  if (onlyUpcoming) {
    params.params["filters[eventDate][endDate][$gt]"] =
      new Date().toISOString();
  }

  return GET_DATA(params);
};

export const getSingleEventInfo = async (eventId) => {
  return GET_DATA({
    endpoint: "events",
    params: {
      populate: [
        "name",
        "url",
        "eventDate",
        "location",
        "content",
        "content.image",
        "artist",
        "artists",
        "ticketsLink",
        "coverArtwork",
        "labels",
        "relatedArticles",
        "selfHosted",
        "organizer",
        "meta",
      ],
      "filters[url][$eq]": eventId,
      "pagination[pageSize]": 1,
      "pagination[page]": 1,
    },
  });
};
