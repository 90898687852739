import Section from "../PageElements/Section";
import React, { useState } from "react";
import MetaData from "../PageElements/Utils/MetaData";
import logo from "../../assets/logo.webp";
import { useParams } from "react-router-dom";
import Pagination from "../PageElements/Utils/Pagination";
import { getPostsInfo } from "../../requests/Blog";
import { useQuery } from "react-query";
import { getPageInfo } from "../../requests/PageData";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import ArticlesList from "../PageElements/Blog/ArticlesList";
import Blog from "../PageElements/RichSnippet/Blog";
import WebPage from "../PageElements/RichSnippet/WebPage";
import CollectionPage from "../PageElements/RichSnippet/CollectionPage";

const pageId = "blog-page";

const ArticleListPage = (props) => {
  const { pageNr } = useParams();
  const [currentPage, setCurrentPage] = useState(pageNr ? pageNr : 1);

  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  const { data, isError, isLoading } = useQuery(
    ["posts", currentPage, props.limit],
    () => getPostsInfo(currentPage, props.limit),
    {
      keepPreviousData: true,
    },
  );

  const pageData = pageDataRaw?.data;
  const articlesData = data?.data;
  const paginationData = data?.meta?.pagination;

  if (isPageDataError || isError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {pageData && (
        <MetaData
          title="Articles"
          keywords={pageData.attributes?.metaKeywords}
          description={pageData.attributes?.metaDescription}
          image={logo}
        />
      )}
      {articlesData && (
        <>
          <CollectionPage
            mainEntityId={window.location.href + "#Blog"}
            shortDesc={pageData.attributes?.title}
            altName={pageData.attributes?.title}
            description={pageData.attributes?.content}
          />
          <Blog articles={articlesData} />
        </>
      )}
      <div>
        {pageData && pageData.attributes?.title && (
          <Section
            title={pageData.attributes?.title}
            contentBefore={pageData.attributes?.content}
            inverted={true}
            isMainTitle={true}
          />
        )}
        {pageData && (
          <Section inverted={false}>
            {articlesData && <ArticlesList articlesListData={articlesData} />}
            {paginationData && paginationData.pageCount > 1 && (
              <Pagination
                page={paginationData.page}
                pageCount={paginationData.pageCount}
                onPageChange={setCurrentPage}
                rootPath={"/articles"}
                paginatedPath={"/articles/page/"}
              />
            )}
          </Section>
        )}
      </div>
    </>
  );
};

export default ArticleListPage;
