import { Link } from "react-router-dom";

const ArticleItem = (props) => {
  const { title, thumbnailImg, excerpt, articleLink } = props;

  return (
    <div className={`max-w-full shadow-lg my-2`}>
      <div className={`relative aspect-square`}>
        <Link className={`aspect-square`} to={"/article/" + articleLink}>
          <img
            className={`max-w-none w-full h-full object-cover object-center`}
            loading="lazy"
            src={thumbnailImg}
            alt={title}
            width="500px"
            height="262px"
          />
        </Link>
      </div>
      <div className={`px-2 pt-3 pb-1 relative bg-white text-black`}>
        <Link to={"/article/" + articleLink}>
          <h3 className={`sm:text-3xl text-2xl secondary-font`}>{title}</h3>
        </Link>
        <p>{excerpt}</p>
        <Link
          to={"/article/" + articleLink}
          className={`block text-right mt-5`}
        >
          <span className={`text-md underline underline-offset-1`}>
            Learn More >
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ArticleItem;
