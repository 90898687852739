import GET_DATA from "../util/dataRequest";

export const getArtistsBasicInfo = async () => {
  return GET_DATA({
    endpoint: "artists",
    params: { populate: ["name", "image", "url", "link", "socialLinks"] },
  });
};

export const getSingleArtistInfo = async (artistId) => {
  return GET_DATA({
    endpoint: "artists",
    params: {
      populate: [
        "name",
        "url",
        "image",
        "socialLinks",
        "biography",
        "link",
        "music_feed_sets.artwork",
        "clubsPlayed",
        "releases.artwork",
      ],
      "filters[url][$eq]": artistId,
      "pagination[pageSize]": 1,
      "pagination[page]": 1,
    },
  });
};
