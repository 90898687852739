const menuItems = [
  { name: "Home", link: "/" },
  { name: "Events", link: "/events" },
  { name: "Artists / Booking", link: "/artists" },
  { name: "Community", link: "/community" },
  { name: "Music Feed", link: "/music-feed" },
  { name: "Blog", link: "/articles" },
  { name: "Contact Us", link: "/contact" },
];

export default menuItems;
