import Body from "./PageElements/Body";
import Footer from "./PageElements/Footer";
import Header from "./PageElements/Header";
import { Outlet } from "react-router-dom";
import RichSnippets from "./PageElements/RichSnippets";
import ScrollToTop from "./PageElements/Utils/ScrollToTop";
import LoadConfigurations from "./PageElements/Utils/LoadConfigurations";

const Root = () => {
  return (
    <div className={`w-full min-h-screen`}>
      <Body>
        <LoadConfigurations />
        <ScrollToTop />
        <Header />
        <RichSnippets />
        <Outlet />
        <Footer />
      </Body>
    </div>
  );
};

export default Root;
