import ElementAnimation from "../Utils/ElementAnimation";

const ArtistSocials = (props) => {
  const { email, facebook, instagram, soundcloud, youtube } =
    props.socialLinks || {};

  return (
    <div className={props.className}>
      {email && (
        <ElementAnimation animation="fadeInUp" delay={200}>
          <a target="_blank" className={`block p-2`} href={`mailto:` + email}>
            <i className={`email-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {facebook && (
        <ElementAnimation animation="fadeInUp" delay={250}>
          <a target="_blank" className={`block p-2`} href={facebook}>
            <i className={`fb-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {instagram && (
        <ElementAnimation animation="fadeInUp" delay={300}>
          <a target="_blank" className={`block p-2`} href={instagram}>
            <i className={`ig-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {soundcloud && (
        <ElementAnimation animation="fadeInUp" delay={350}>
          <a target="_blank" className={`block p-2`} href={soundcloud}>
            <i className={`soundcloud-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
      {youtube && (
        <ElementAnimation animation="fadeInUp" delay={400}>
          <a target="_blank" className={`block p-2`} href={youtube}>
            <i className={`youtube-icon inline-flex w-[20px] h-[20px]`} />
          </a>
        </ElementAnimation>
      )}
    </div>
  );
};

export default ArtistSocials;
