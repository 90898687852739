import config from "../../../config/site";

const BlogPosting = (props) => {
  const { article } = props;

  const jsonContent = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    "@id": window.location.origin + "/" + article.url + "#BlogPosting",
    headline: article.title,
    name: article.title,
    description: article.excerpt,
    datePublished: article.publishDate,
    dateModified: article.updateDate,
    author: {
      "@id": window.location.origin + "#Organization",
    },
    image: {
      "@type": "ImageObject",
      "@id": window.location.href + "/" + article.url + "#Thumbnail",
      url:
        config.backend_url +
        article?.thumbnail?.data?.attributes?.formats?.medium?.url,
    },
    url: article.url,
    keywords: article.meta.metaKeywords,
  };

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default BlogPosting;
