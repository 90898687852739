import { useState } from "react";
import config from "../../../config/site";

const defaultValues = {
  viewAllTitle: "View All Releases",
};

const Releases = (props) => {
  const { releaseData } = props;

  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className={``}>
      <ul
        className={`${
          showAll
            ? "grid releases-grid-fill items-center"
            : "flex flex-no-wrap overflow-x-scroll scrolling-touch items-start scrollbar-hidden"
        } mb-4`}
      >
        {releaseData &&
          releaseData.map((release) => (
            <li
              className={`flex-none m-4 ml-4 shadow-lg max-w-[156px]`}
              key={release.attributes.link}
            >
              <a href={release.attributes.link} about="_blank">
                <div>
                  <img
                    loading="lazy"
                    src={
                      config.backend_url +
                      release.attributes.artwork.data.attributes.formats
                        .thumbnail.url
                    }
                    alt={release.attributes.title}
                  />
                </div>
              </a>
            </li>
          ))}
      </ul>
      <div className={`w-full`}>
        <span
          className={`block text-center underline cursor-pointer`}
          onClick={handleShowAll}
        >
          {props.viewAllTitle ? props.viewAllTitle : defaultValues.viewAllTitle}{" "}
          >
        </span>
      </div>
    </div>
  );
};

export default Releases;
