import Event from "../Events/Event";
import { useMemo } from "react";
import config from "../../../config/site";
import ElementAnimation from "../Utils/ElementAnimation";

const EventsList = (props) => {
  const { eventsListData } = props;

  useMemo(() => {
    if (eventsListData === undefined) return;

    eventsListData.sort((a, b) => {
      let aDate = new Date(a.attributes.eventDate.startDate);
      let bDate = new Date(b.attributes.eventDate.startDate);

      if (aDate > bDate) {
        return -1;
      }

      if (aDate < bDate) {
        return 1;
      }

      return 0;
    });
  }, [eventsListData]);

  return (
    <div key={Math.random()}>
      {eventsListData.length ? (
        <ul className={`grid md:grid-cols-2 grid-cols-1 mb-4`}>
          {eventsListData &&
            eventsListData.map((event, index) => (
              <li className={`mr-4 ml-4 md:pb-4`} key={index}>
                <ElementAnimation
                  animation="fadeInUp"
                  delay={200 + index * 100}
                >
                  <Event
                    name={event.attributes.name}
                    image={
                      config.backend_url +
                      event.attributes.coverArtwork.data.attributes.formats
                        .medium.url
                    }
                    labels={event.attributes.labels}
                    date={event.attributes.eventDate.startDate}
                    location={event.attributes.location.locationName}
                    locationLink={event.attributes.location.locationLink}
                    eventLink={event.attributes.url}
                    key={event.eventLink}
                  />
                </ElementAnimation>
              </li>
            ))}
        </ul>
      ) : (
        <ElementAnimation animation="fadeIn" delay={300}>
          <p>There are currently no events to display.</p>
        </ElementAnimation>
      )}
    </div>
  );
};

export default EventsList;
