const ComponentLoadingOverlay = () => {
  return (
    <div
      className={`z-[1000] w-full h-full absolute bg-white/20 table backdrop-blur-xl`}
    >
      <div className={`align-middle table-cell`}>
        <h1 className={`text-center`}>Loading...</h1>
      </div>
    </div>
  );
};

export default ComponentLoadingOverlay;
