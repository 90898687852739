import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getGeneralConfig } from "../../requests/PageData";
import ErrorScreen from "./Utils/ErrorScreen";
import LoadingScreen from "./Utils/LoadingScreen";
import { lazy } from "react";
import ElementAnimation from "./Utils/ElementAnimation";

const FooterMountains = lazy(() => import("./FooterMountains.js"));

const Footer = (props) => {
  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isConfigError) {
    return <ErrorScreen />;
  }

  if (isConfigLoading) {
    return <LoadingScreen />;
  }

  const configData = configDataRaw?.data?.attributes;

  return (
    <div className={`mt-auto`}>
      <div className={`max-w-full z-0 relative`}>
        <div className={`row-start-1 col-start-1 absolute w-full z-10`}>
          <div
            className={`col-start-1 h-[50%] w-full z-10 bg-gradient-to-t from-transparent to-black`}
          >
            {configData && (
              <div className={`text-center pt-[30px]`}>
                {configData?.socialLinks?.email && (
                  <ElementAnimation
                    className={`inline-block`}
                    animation="fadeInUp"
                    delay={300}
                  >
                    <a
                      aria-label="Tereda Email"
                      target="_blank"
                      className={`p-3 ease-in-out duration-300 delay-300`}
                      href={`mailto:` + configData?.socialLinks?.email}
                    >
                      <i
                        className={`email-icon inline-flex w-[20px] h-[20px]`}
                      />
                    </a>
                  </ElementAnimation>
                )}
                {configData?.socialLinks?.facebook && (
                  <ElementAnimation
                    className={`inline-block`}
                    animation="fadeInUp"
                    delay={350}
                  >
                    <a
                      aria-label="Tereda Facebook"
                      target="_blank"
                      className={`p-3`}
                      href={
                        !/^https?:\/\//i.test(configData?.socialLinks?.facebook)
                          ? `http://${configData?.socialLinks?.facebook}`
                          : configData?.socialLinks?.facebook
                      }
                    >
                      <i className={`fb-icon inline-flex w-[20px] h-[20px]`} />
                    </a>
                  </ElementAnimation>
                )}
                {configData?.socialLinks?.instagram && (
                  <ElementAnimation
                    className={`inline-block`}
                    animation="fadeInUp"
                    delay={400}
                  >
                    <a
                      aria-label="Tereda Instagram"
                      target="_blank"
                      className={`p-3`}
                      href={
                        !/^https?:\/\//i.test(
                          configData?.socialLinks?.instagram,
                        )
                          ? `http://${configData?.socialLinks?.instagram}`
                          : configData?.socialLinks?.instagram
                      }
                    >
                      <i className={`ig-icon inline-flex w-[20px] h-[20px]`} />
                    </a>
                  </ElementAnimation>
                )}
                {configData?.socialLinks?.soundcloud && (
                  <ElementAnimation
                    className={`inline-block`}
                    animation="fadeInUp"
                    delay={450}
                  >
                    <a
                      aria-label="Tereda SoundCloud"
                      target="_blank"
                      className={`p-3`}
                      href={
                        !/^https?:\/\//i.test(
                          configData?.socialLinks?.soundcloud,
                        )
                          ? `http://${configData?.socialLinks?.soundcloud}`
                          : configData?.socialLinks?.soundcloud
                      }
                    >
                      <i
                        className={`soundcloud-icon inline-flex w-[20px] h-[20px]`}
                      />
                    </a>
                  </ElementAnimation>
                )}
                {configData?.socialLinks?.youtube && (
                  <ElementAnimation
                    className={`inline-block`}
                    animation="fadeInUp"
                    delay={500}
                  >
                    <a
                      aria-label="Tereda YouTube"
                      target="_blank"
                      className={`p-3`}
                      href={
                        !/^https?:\/\//i.test(configData?.socialLinks?.youtube)
                          ? `http://${configData?.socialLinks?.youtube}`
                          : configData?.socialLinks?.youtube
                      }
                    >
                      <i
                        className={`youtube-icon inline-flex w-[20px] h-[20px]`}
                      />
                    </a>
                  </ElementAnimation>
                )}
              </div>
            )}
          </div>
        </div>
        <FooterMountains />
        <div className={`w-full bg-black text-white text-center`}>
          <span>© All rights reserved. | Tereda Events</span>
        </div>
        <div className={`w-full bg-black text-white text-center text-sm py-4`}>
          <span>
            <Link
              to={"/terms-and-conditions"}
              className={`underline underline-offset-1`}
            >
              Terms & Conditions
            </Link>{" "}
            |{" "}
            <Link
              to={"/privacy-policy"}
              className={`underline underline-offset-1`}
            >
              Privacy Policy
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
