import { useEffect, useState } from "react";
import { POST_DATA } from "../../../util/dataRequest";
import config from "../../../config/site";
import { Link } from "react-router-dom";
import ElementAnimation from "../Utils/ElementAnimation";

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectTouched, setSubjectTouched] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTouched, setMessageTouched] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [acceptTermsAndConditionsTouched, setAcceptTermsAndConditionsTouched] =
    useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [acceptPrivacyPolicyTouched, setAcceptPrivacyPolicyTouched] =
    useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${config.recaptcha.site_key}`,
    );
  }, []);

  const nameIsValid = name.trim() !== "";
  const emailIsEmpty = email.trim() === "";
  const emailIsValid =
    email.trim() !== "" &&
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    );
  const subjectIsValid = subject.trim() !== "";
  const messageIsValid = message.trim() !== "";
  const isFormValid =
    nameIsValid &&
    !emailIsEmpty &&
    emailIsValid &&
    subjectIsValid &&
    messageIsValid &&
    acceptTermsAndConditions &&
    acceptPrivacyPolicy;

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameTouched(true);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailTouched(true);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
    setSubjectTouched(true);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setMessageTouched(true);
  };

  const handleAcceptTermsAndConditionsChange = (event) => {
    setAcceptTermsAndConditions(event.target.checked);
    setAcceptTermsAndConditionsTouched(true);
  };

  const handleAcceptPrivacyPolicy = (event) => {
    setAcceptPrivacyPolicy(event.target.checked);
    setAcceptPrivacyPolicyTouched(true);
  };

  const fieldRequiredError = (
    <span className={`text-md text-red-700 mt-2 block`}>
      This field is required!
    </span>
  );
  const fieldDataInvalidError = (
    <span className={`text-md text-red-700 mt-2 block`}>Invalid value!</span>
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.recaptcha.site_key, { action: "submit" })
        .then((token) => {
          const data = {
            data: {
              name: name,
              email: email,
              subject: subject,
              message: message,
              acceptTermsAndConditions: acceptTermsAndConditions,
              acceptPrivacyPolicy: acceptPrivacyPolicy,
            },
          };

          POST_DATA({
            endpoint: "contact-form-entry",
            body: data,
            recaptchaToken: token,
          }).then(() => {
            setName("");
            setNameTouched(false);
            setEmail("");
            setEmailTouched(false);
            setSubject("");
            setSubjectTouched(false);
            setMessage("");
            setMessageTouched(false);
            setAcceptTermsAndConditions(false);
            setAcceptTermsAndConditionsTouched(false);
            setAcceptPrivacyPolicy(false);
            setAcceptPrivacyPolicyTouched(false);
            setIsFormSubmitted(true);
          });
        });
    });
  };

  return (
    <div className={`md:w-[70%] w-[95%] mx-auto`}>
      {!isFormSubmitted && (
        <ElementAnimation animation="fadeInUp" delay={200}>
          <form onSubmit={handleSubmit}>
            <div className={`pb-6`}>
              <label
                htmlFor="name"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Name
              </label>
              <input
                id="name"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"name"}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameChange}
                type={"text"}
                required
              />
              {nameTouched && !nameIsValid && fieldRequiredError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="email"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                E-Mail
              </label>
              <input
                id="email"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"email"}
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailChange}
                type={"text"}
                required
              />
              {emailTouched && emailIsEmpty && fieldRequiredError}
              {emailTouched &&
                !emailIsEmpty &&
                !emailIsValid &&
                fieldDataInvalidError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="subject"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Subject
              </label>
              <input
                id="subject"
                className={`w-[100%] line-he block leading-4 h-12 text-black px-3`}
                name={"subject"}
                value={subject}
                onChange={handleSubjectChange}
                onBlur={handleSubjectChange}
                type={"text"}
                required
              />
              {subjectTouched && !subjectIsValid && fieldRequiredError}
            </div>
            <div className={`pb-6`}>
              <label
                htmlFor="message"
                className={`secondary-font sm:text-4xl text-2xl`}
              >
                Message
              </label>
              <textarea
                id="message"
                className={`w-[100%] line-he block leading-4 h-40 text-black px-3`}
                name={"message"}
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageChange}
                required
              />
              {messageTouched && !messageIsValid && fieldRequiredError}
            </div>
            <div>
              <label
                htmlFor="acceptTermsAndConditions"
                className={`secondary-font text-xl checkbox-container`}
              >
                I accept the{" "}
                <Link
                  to={"/terms-and-conditions"}
                  className={`underline underline-offset-1`}
                >
                  Terms & Conditions
                </Link>{" "}
                of this site
                <input
                  id="acceptTermsAndConditions"
                  name="acceptTermsAndConditions"
                  type="checkbox"
                  checked={acceptTermsAndConditions}
                  onChange={handleAcceptTermsAndConditionsChange}
                  required
                />
                <span className="checkmark"></span>
              </label>
              {!acceptTermsAndConditions &&
                acceptTermsAndConditionsTouched &&
                fieldRequiredError}
            </div>
            <div>
              <label
                htmlFor="acceptPrivacyPolicy"
                className={`secondary-font text-xl checkbox-container`}
              >
                I accept the{" "}
                <Link
                  to={"/privacy-policy"}
                  className={`underline underline-offset-1`}
                >
                  Privacy Policy
                </Link>{" "}
                of this site
                <input
                  className={`checkbox w-5 h-5`}
                  id="acceptPrivacyPolicy"
                  name="acceptPrivacyPolicy"
                  type="checkbox"
                  checked={acceptPrivacyPolicy}
                  onChange={handleAcceptPrivacyPolicy}
                  required
                />
                <span className="checkmark"></span>
              </label>
              {!acceptPrivacyPolicy &&
                acceptPrivacyPolicyTouched &&
                fieldRequiredError}
            </div>
            <div>
              <button
                className={`${
                  !isFormValid ? "text-gray-500" : "text-black"
                } w-[100%] bg-white secondary-font sm:text-4xl text-2xl py-2`}
                type="submit"
                onSubmit={handleSubmit}
                disabled={!isFormValid}
              >
                Send Message
              </button>
            </div>
          </form>
        </ElementAnimation>
      )}
      {isFormSubmitted && (
        <div className={`text-center`}>
          <span className={`secondary-font text-xl`}>
            Thanks for contacting us! We'll reach out to you as soon as we can!
          </span>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
