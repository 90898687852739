import Event from "../Events/Event";
import { Link } from "react-router-dom";
import config from "../../../config/site";
import { getEventsInfo } from "../../../requests/Events";
import { useQuery } from "react-query";
import ComponentLoadingOverlay from "../Utils/ComponentLoadingOverlay";
import Events from "../RichSnippet/Events";
import { getGeneralConfig } from "../../../requests/PageData";
import ErrorScreen from "../Utils/ErrorScreen";
import Section from "../Section";
import ElementAnimation from "../Utils/ElementAnimation";

const EventsSlider = (props) => {
  const { limit, artistId = false, onlyUpcoming = false } = props;

  const { data, isError, isLoading } = useQuery(
    ["events-list", 1, limit, artistId, onlyUpcoming],
    () => getEventsInfo(1, limit, onlyUpcoming, artistId),
    {
      keepPreviousData: true,
    },
  );

  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isError || isConfigError) {
    return <ErrorScreen />;
  }

  if (isLoading || isConfigLoading) {
    return <ComponentLoadingOverlay />;
  }

  const configData = configDataRaw?.data?.attributes?.eventsSlider;
  const eventsData = data?.data;

  return (
    <Section
      title={configData.title}
      contentBefore={
        artistId ? configData.content : configData.contentOnArtistPage
      }
      inverted={false}
    >
      <Events eventsData={eventsData} addItemList={true} />
      <div>
        <ul
          className={`flex md:flex-wrap flex-no-wrap overflow-x-scroll scrolling-touch items-start mb-4 scrollbar-hidden`}
        >
          {eventsData &&
            eventsData.map((event, index) => (
              <li
                className={`flex-none w-2/3 md:flex lg:w-[calc(33.333333%-2rem)] md:w-[calc(50%-2rem)] md:flex-wrap mr-4 ml-4 md:pb-4`}
                key={event.attributes.url}
              >
                <ElementAnimation
                  animation="fadeInUp"
                  delay={200 + index * 100}
                >
                  <Event
                    name={event.attributes.name}
                    image={
                      config.backend_url +
                      event?.attributes?.coverArtwork?.data?.attributes?.formats
                        ?.eventsslider?.url
                    }
                    labels={event.attributes.labels}
                    date={event.attributes.eventDate.startDate}
                    location={event.attributes.location.locationName}
                    locationLink={event.attributes.location.locationLink}
                    eventLink={event.attributes.url}
                    key={event.attributes.url}
                  ></Event>
                </ElementAnimation>
              </li>
            ))}
        </ul>
        <div className={`w-full`}>
          <ElementAnimation animation="fadeIn" delay={300}>
            <Link className={`block text-center underline`} to="/events">
              <span>{configData.viewAllEventsLinkTitle} ></span>
            </Link>
          </ElementAnimation>
        </div>
      </div>
    </Section>
  );
};

export default EventsSlider;
