import Section from "../Section";
import LinkButton from "./LinkButton";
import ElementAnimation from "./ElementAnimation";

const ContactUsButton = (props) => {
  return (
    <Section
      inverted={true}
      contentBefore="Got any question? Feel free to contact us by clicking on the button below!"
    >
      <div className={`md:w-[70%] w-[95%] mx-auto`}>
        <ElementAnimation animation="fadeIn" delay={300}>
          <LinkButton link="/contact" title="Send Message" inverted={true} />
        </ElementAnimation>
      </div>
    </Section>
  );
};

export default ContactUsButton;
