import Section from "../PageElements/Section";
import MetaData from "../PageElements/Utils/MetaData";
import { getPageInfo } from "../../requests/PageData";
import { useQuery } from "react-query";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import React from "react";

const SimplePage = (props) => {
  const { pageId } = props;

  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  if (isPageDataError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading) {
    return <LoadingScreen />;
  }

  const pageData = pageDataRaw?.data;

  return (
    <>
      {pageData && <MetaData title={pageData?.attributes?.title} />}
      <div>
        {pageData && pageData.attributes?.title && (
          <Section title={pageData.attributes.title} inverted={true} />
        )}
        {pageData?.attributes?.content && (
          <Section
            withPadding={false}
            contentBefore={pageData?.attributes?.content}
          />
        )}
      </div>
    </>
  );
};

export default SimplePage;
