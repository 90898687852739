import Section from "../PageElements/Section";
import ContactForm from "../PageElements/Contact/ContactForm";
import MetaData from "../PageElements/Utils/MetaData";
import logo from "../../assets/logo.webp";
import { getContactInfo } from "../../requests/Contact";
import { useQuery } from "react-query";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import { getGeneralConfig } from "../../requests/PageData";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";

const ContactPage = (props) => {
  const {
    data: contactInfoRaw,
    isError,
    isLoading,
  } = useQuery(["contact-info"], () => getContactInfo());

  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isError || isConfigError) {
    return <ErrorScreen />;
  }

  if (isLoading || isConfigLoading) {
    return <LoadingScreen />;
  }

  const contactInfo = contactInfoRaw?.data;
  const configData = configDataRaw?.data?.attributes;

  return (
    <>
      {contactInfo && (
        <MetaData
          title="Contact"
          keywords={contactInfo.attributes?.metaKeywords}
          description={contactInfo.attributes?.metaDescription}
          image={logo}
        />
      )}
      {contactInfo && (
        <div>
          <Section
            title={contactInfo.attributes?.title}
            contentBefore={contactInfo.attributes?.content}
            inverted={true}
            isMainTitle={true}
          >
            <div className={props.className}>
              {configData?.socialLinks?.email && (
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeInUp"
                  delay={300}
                >
                  <a
                    aria-label="Tereda Email"
                    target="_blank"
                    className={`block p-2`}
                    href={`mailto:` + configData?.socialLinks?.email}
                  >
                    <i className={`email-icon inline-flex w-[20px] h-[20px]`} />
                    <span className={`ml-4 relative bottom-[5px]`}>
                      {configData?.socialLinks?.email}
                    </span>
                  </a>
                </ElementAnimation>
              )}
              {configData?.socialLinks?.facebook && (
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeInUp"
                  delay={350}
                >
                  <a
                    aria-label="Tereda Facebook"
                    target="_blank"
                    className={`block p-2`}
                    href={configData?.socialLinks?.facebook}
                  >
                    <i className={`fb-icon inline-flex w-[20px] h-[20px]`} />
                    <span className={`ml-4 relative bottom-[5px]`}>
                      {configData?.socialLinks?.facebook}
                    </span>
                  </a>
                </ElementAnimation>
              )}
              {configData?.socialLinks?.instagram && (
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeInUp"
                  delay={400}
                >
                  <a
                    aria-label="Tereda Instagram"
                    target="_blank"
                    className={`block p-2`}
                    href={configData?.socialLinks?.instagram}
                  >
                    <i className={`ig-icon inline-flex w-[20px] h-[20px]`} />
                    <span className={`ml-4 relative bottom-[5px]`}>
                      {configData?.socialLinks?.instagram}
                    </span>
                  </a>
                </ElementAnimation>
              )}
              {configData?.socialLinks?.soundcloud && (
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeInUp"
                  delay={450}
                >
                  <a
                    aria-label="Tereda SoundCloud"
                    target="_blank"
                    className={`block p-2`}
                    href={configData?.socialLinks?.soundcloud}
                  >
                    <i
                      className={`soundcloud-icon inline-flex w-[20px] h-[20px]`}
                    />
                    <span className={`ml-4 relative bottom-[5px]`}>
                      {configData?.socialLinks?.soundcloud}
                    </span>
                  </a>
                </ElementAnimation>
              )}
              {configData?.socialLinks?.youtube && (
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeInUp"
                  delay={500}
                >
                  <a
                    aria-label="Tereda YouTube"
                    target="_blank"
                    className={`block p-2`}
                    href={configData?.socialLinks?.youtube}
                  >
                    <i
                      className={`youtube-icon inline-flex w-[20px] h-[20px]`}
                    />
                    <span className={`ml-4 relative bottom-[5px]`}>
                      {configData?.socialLinks?.youtube}
                    </span>
                  </a>
                </ElementAnimation>
              )}
            </div>
          </Section>
          <Section
            contentBefore={contactInfo?.attributes?.contentBeforeForm}
            inverted={true}
          >
            <ContactForm />
          </Section>
        </div>
      )}
    </>
  );
};

export default ContactPage;
