import { useEffect, useRef } from "react";

const ElementAnimation = (props) => {
  const { animation, className, delay = 150, duration = 500 } = props;

  const containerRef = useRef(null);
  const observer = new IntersectionObserver((entries, observer) => {
    entries
      .filter((e) => e.isIntersecting)
      .forEach((entry) => {
        let animationClassName = entry.target.getAttribute(
          "data-animation-class",
        );
        entry.target.classList.add(animationClassName);
        observer.unobserve(entry.target);
      });
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.setAttribute("data-animation-class", animation);
      containerRef.current.setAttribute(
        "style",
        `animation-delay: ${delay}ms;animation-duration: ${duration}ms`,
      );
      observer.observe(containerRef.current);
    }
  }, [containerRef]);

  return (
    <div
      className={`animated ${className ? className : ""}`}
      ref={containerRef}
    >
      {props.children}
    </div>
  );
};

export default ElementAnimation;
