import { Link } from "react-router-dom";
import config from "../../config/site";
import { getArtistsBasicInfo } from "../../requests/Artists";
import { useQuery } from "react-query";
import ComponentLoadingOverlay from "./Utils/ComponentLoadingOverlay";
import { getGeneralConfig } from "../../requests/PageData";
import Section from "./Section";
import ElementAnimation from "./Utils/ElementAnimation";

const ArtistsShowcase = (props) => {
  const { data, isError, isLoading } = useQuery(["artists-basic-list"], () =>
    getArtistsBasicInfo(),
  );

  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isLoading || isConfigLoading) {
    return <ComponentLoadingOverlay />;
  }

  if (isError || isConfigError) {
    return <ComponentLoadingOverlay />;
  }

  const artistData = data?.data;
  const configData = configDataRaw?.data?.attributes?.artistsShowcase;

  return (
    <>
      {configData && (
        <Section
          title={configData.title}
          contentBefore={configData.content}
          inverted={true}
        >
          <div className={`flex flex-wrap justify-center`}>
            {artistData &&
              artistData.map((artist, index) => (
                <div
                  className={`text-center md:flex-col-5 flex-col-3 p-5 min-w-[80px]`}
                  key={artist?.attributes?.url}
                >
                  <ElementAnimation
                    animation="fadeInUp"
                    delay={200 + index * 100}
                  >
                    <Link to={`/artist/` + artist?.attributes?.url}>
                      <img
                        loading="lazy"
                        src={
                          config.backend_url +
                          artist?.attributes?.image?.data?.attributes?.formats
                            ?.xs?.url
                        }
                        alt={
                          artist?.attributes?.image?.data?.attributes
                            ?.alternativeText
                        }
                        width="177px"
                        height="177px"
                      />
                      <h3 className={`md:text-2xl text-xl secondary-font`}>
                        {artist?.attributes?.name}
                      </h3>
                    </Link>
                  </ElementAnimation>
                </div>
              ))}
          </div>
        </Section>
      )}
    </>
  );
};

export default ArtistsShowcase;
