import { Turn as MenuButton } from "hamburger-react";
import logo from "../../assets/logo-header.webp";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuItems from "../../config/menu";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isStickyHeaderDisplayed, setIsStickyHeaderDisplayed] = useState(false);
  const location = useLocation();

  const onToggleHandler = (toggled) => {
    setOpen((prevState) => {
      return !prevState;
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsStickyHeaderDisplayed(true);
    } else if (!open) {
      setIsStickyHeaderDisplayed(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  let domain = false;
  let domainParts = window.location.hostname.split(".");

  if (domainParts.length > 2) {
    domain =
      window.location.protocol +
      "//" +
      domainParts.slice(domainParts.length - 2, domainParts.length).join(".");
  }

  return (
    <div
      className={`flex justify-between flex-wrap flex-initial items-center z-[2] w-full transition-all ease-in-out duration-300 ${
        location.pathname === "/" &&
        window.location.hostname.split(".").length === 2
          ? "fixed"
          : "sticky"
      } top-0 md:px-[50px] md:py-[20px] sm:px[20px] px-[5px] py-[10px] ${
        (isStickyHeaderDisplayed || location.pathname) !== "/" ||
        window.location.hostname.split(".").length > 2
          ? "bg-white"
          : "bg-transparent"
      }`}
    >
      <div className="float-left h-full">
        <div className="flex w-100 h-100 max-h-[85px] items-center float-left">
          <NavLink
            aria-label="Home"
            to={window.location.hostname.split(".").length > 2 ? domain : "/"}
          >
            <img
              loading="lazy"
              className={`max-h-[85px] transition-all ease-in-out duration-300 ${
                isStickyHeaderDisplayed ||
                location.pathname !== "/" ||
                window.location.hostname.split(".").length > 2
                  ? "opacity-100"
                  : "invisible opacity-0"
              }`}
              src={logo}
              alt="Logo"
            />
          </NavLink>
        </div>
      </div>
      <div className="h-full z-50">
        <MenuButton label={"Menu"} toggled={open} onToggle={onToggleHandler} />
      </div>
      <div
        className={`bg-white/70 backdrop-blur-lg absolute ease-in-out duration-300 top-0 left-0 sm:pt-0 pt-6 max-w-full w-[100vw] h-[100vh] ${
          open ? "opacity-100" : "invisible opacity-0"
        }`}
      >
        <ul
          className={`md:items-center absolute md:static left-0 w-full md:w-auto sm:pl-12 pl-6 sm:pt-12 pt-6 sm:text-5xl text-3xl`}
        >
          {menuItems.map((link, index) => (
            <li
              style={open ? { transitionDelay: 100 + index * 60 + "ms" } : {}}
              className={`pb-[18px] secondary-font ease-in duration-300${
                open ? " opacity-100" : " invisible opacity-0"
              }`}
              key={link.name}
            >
              <NavLink
                className={({ isActive }) => (isActive ? "text-gray-500" : "")}
                onClick={onToggleHandler}
                to={
                  window.location.hostname.split(".").length > 2
                    ? domain + link.link
                    : link.link
                }
                end
                aria-label={link.name}
              >
                <span>{link.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
