import Section from "../PageElements/Section";
import EventsList from "../PageElements/Events/EventsList";
import React, { useMemo, useState } from "react";
import MetaData from "../PageElements/Utils/MetaData";
import logo from "../../assets/logo.webp";
import { useParams } from "react-router-dom";
import Pagination from "../PageElements/Utils/Pagination";
import { getEventsInfo } from "../../requests/Events";
import { useQuery } from "react-query";
import { getPageInfo } from "../../requests/PageData";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import Events from "../PageElements/RichSnippet/Events";
import CollectionPage from "../PageElements/RichSnippet/CollectionPage";

const pageId = "events-list-page";

const EventListPage = (props) => {
  const { pageNr } = useParams();
  const [currentPage, setCurrentPage] = useState(pageNr ? pageNr : 1);

  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  const { data, isError, isLoading } = useQuery(
    ["events-list", currentPage, props.limit],
    () => getEventsInfo(currentPage, props.limit),
    {
      keepPreviousData: true,
    },
  );

  const pageData = pageDataRaw?.data;
  const eventsData = data?.data;
  const paginationData = data?.meta?.pagination;

  const upcomingEvents = useMemo(() => {
    if (eventsData === undefined || eventsData === null) return [];

    return eventsData.filter((event) => {
      return new Date(event.attributes.eventDate.startDate) > new Date();
    });
  }, [eventsData]);

  const pastEvents = useMemo(() => {
    if (eventsData === undefined || eventsData === null) return [];

    return eventsData.filter((event) => {
      return new Date(event.attributes.eventDate.startDate) <= new Date();
    });
  }, [eventsData]);

  if (isPageDataError || isError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {pageData && (
        <MetaData
          title="Events"
          keywords={pageData.attributes?.metaKeywords}
          description={pageData.attributes?.metaDescription}
          image={logo}
        />
      )}
      {pageData && (
        <>
          <CollectionPage
            mainEntityId={window.location.href + "#EventsList"}
            title={pageData.attributes?.title}
            altName={pageData.attributes?.title}
            description={pageData.attributes?.content}
          />
          <Events eventsData={eventsData} addItemList={true} />
        </>
      )}
      <div>
        {pageData && pageData.attributes?.title && (
          <Section
            title={pageData.attributes?.title}
            contentBefore={pageData.attributes?.content}
            inverted={true}
            isMainTitle={true}
          />
        )}
        {pageData && (
          <Section
            title={pageData.attributes?.upcomingEventsTitle}
            inverted={false}
          >
            {upcomingEvents && (
              <EventsList eventsListData={upcomingEvents} key={"upcoming"} />
            )}
          </Section>
        )}
        <MusicFeedSection />
        {pageData && pastEvents && (
          <Section
            title={pageData.attributes?.pastEventsTitle}
            inverted={false}
          >
            {pastEvents && (
              <EventsList eventsListData={pastEvents} key={"past"} />
            )}
            {paginationData && paginationData.pageCount > 1 && (
              <Pagination
                page={paginationData.page}
                pageCount={paginationData.pageCount}
                onPageChange={setCurrentPage}
                rootPath={"/events"}
                paginatedPath={"/events/page/"}
              />
            )}
          </Section>
        )}
      </div>
    </>
  );
};

export default EventListPage;
