import Section from "../PageElements/Section";
import { Link, useParams } from "react-router-dom";
import LinkButton from "../PageElements/Utils/LinkButton";
import ContactUsButton from "../PageElements/Utils/ContactUsButton";
import MailChimpForm from "../PageElements/MailChimp/MailChimpForm";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import MetaData from "../PageElements/Utils/MetaData";
import config from "../../config/site";
import { getSingleEventInfo } from "../../requests/Events";
import { useQuery } from "react-query";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import ContentSection from "../PageElements/Utils/ContentSection";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";
import Event from "../PageElements/RichSnippet/Event";
import WebPage from "../PageElements/RichSnippet/WebPage";
import React from "react";

const EventDetailPage = (props) => {
  const { eventId } = useParams();
  const { data, isError, isLoading } = useQuery(["event-detail", eventId], () =>
    getSingleEventInfo(eventId),
  );

  if (isError) {
    return <ErrorScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const eventData = data?.data[0];

  return (
    <>
      {eventData && (
        <MetaData
          title={eventData?.attributes?.name}
          keywords={eventData?.attributes?.meta?.metaKeywords}
          description={eventData?.attributes?.meta?.metaDescription}
          image={
            config.backend_url +
            eventData.attributes.coverArtwork.data.attributes.formats.medium.url
          }
        />
      )}
      {eventData && (
        <>
          <WebPage
            mainEntityId={
              window.location.origin + "#Event" + eventData?.attributes?.url
            }
            title={eventData?.attributes?.name}
            description={eventData?.attributes?.content}
          />
          <Event
            eventsData={eventData?.attributes}
            key={eventData?.attributes?.id}
          />
        </>
      )}
      {eventData && (
        <div>
          <Section textNotCentered={true} withPadding={false} />
          <Section withPadding={false}>
            <div className={`grid md:grid-cols-2 grid-cols-1 mb-4`}>
              <div className={`w-full h-full`}>
                <ElementAnimation
                  className={`inline-block`}
                  animation="fadeIn"
                  delay={300}
                >
                  <img
                    className={`w-full`}
                    src={
                      config.backend_url +
                      eventData.attributes.coverArtwork.data.attributes.formats
                        .medium.url
                    }
                    alt={eventData.attributes.name}
                  />
                </ElementAnimation>
              </div>
              <div>
                <Section
                  className={`text-left md:px-8 px-4`}
                  textNotCentered={true}
                  title={eventData.attributes.name}
                  isSecondaryFont={true}
                  titleSm={true}
                  withPadding={false}
                >
                  <div
                    className={`md:block sm:flex block justify-between relative`}
                  >
                    <div className={`md:block`}>
                      <ul>
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={300}
                          >
                            <i
                              className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            <span>
                              {new Date(
                                eventData.attributes.eventDate.startDate,
                              ).toLocaleTimeString("en-us", {
                                weekday: "short",
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </ElementAnimation>
                        </li>
                        {eventData.attributes.eventDate.endDate && (
                          <li>
                            <ElementAnimation
                              className={`inline-block`}
                              animation="fadeInUp"
                              delay={350}
                            >
                              <i
                                className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                              />
                              <span>
                                {new Date(
                                  eventData.attributes.eventDate.endDate,
                                ).toLocaleTimeString("en-us", {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </ElementAnimation>
                          </li>
                        )}
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={400}
                          >
                            <i
                              className={`location-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            {eventData.attributes.location.locationLink ? (
                              <Link
                                to={eventData.attributes.location.locationLink}
                                target="_blank"
                                aria-label={
                                  eventData.attributes.location.locationName
                                }
                              >
                                {eventData.attributes.location.locationName}
                              </Link>
                            ) : (
                              eventData.attributes.location.locationName
                            )}
                          </ElementAnimation>
                        </li>
                        <li>
                          <ElementAnimation
                            className={`inline-block`}
                            animation="fadeInUp"
                            delay={450}
                          >
                            <i
                              className={`fb-black-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
                            />
                            <Link
                              to={eventData.attributes.fbLink}
                              target="_blank"
                              aria-label="Facebook Event"
                            >
                              Facebook Event
                            </Link>
                          </ElementAnimation>
                        </li>
                      </ul>
                    </div>
                    <ElementAnimation
                      className={`block`}
                      animation="fadeIn"
                      delay={500}
                    >
                      <div
                        className={`md:relative sm:absolute relative right-0 bottom-0 w-auto md:mt-4 sm:mt-0 mt-4 min-w-[250px]`}
                      >
                        <LinkButton
                          title="Tickets"
                          link={eventData.attributes.ticketsLink}
                          centered={false}
                          targetBlank={true}
                          className={`mt-8 block`}
                        />
                      </div>
                    </ElementAnimation>
                  </div>
                </Section>
              </div>
            </div>
          </Section>
          {eventData?.attributes?.content?.length > 0 && (
            <Section withPadding={false}>
              {eventData.attributes.content.map((section) => (
                <ContentSection sectionData={section} key={section.id} />
              ))}
            </Section>
          )}
          {(eventData.attributes.artist.length > 0 ||
            eventData.attributes.artists.data.length > 0) && (
            <Section title={"Artists"} inverted={true}>
              <ElementAnimation
                className={`block`}
                animation="fadeInUp"
                delay={300}
              >
                {eventData.attributes.artist.map((artist) => (
                  <div className={`inline-block`} key={artist.name}>
                    {artist.link ? (
                      <a className={`pr-10`} href={artist.link} target="_blank">
                        <span className={`secondary-font sm:text-5xl text-3xl`}>
                          {artist.name} / {artist.country}
                        </span>
                      </a>
                    ) : (
                      <span
                        className={`secondary-font sm:text-5xl text-3xl pr-10`}
                      >
                        {artist.name} / {artist.country}
                      </span>
                    )}
                  </div>
                ))}
                {eventData.attributes.artists.data.map((artist) => (
                  <div className={`inline-block`} key={artist.attributes.name}>
                    <Link
                      className={`pr-10`}
                      to={"/artist/" + artist.attributes.url}
                    >
                      <span className={`secondary-font sm:text-5xl text-3xl`}>
                        {artist.attributes.name +
                          " / " +
                          artist.attributes.residentCountry}
                      </span>
                    </Link>
                  </div>
                ))}
              </ElementAnimation>
            </Section>
          )}
          <MusicFeedSection />
          <MailChimpForm />
          <ContactUsButton />
        </div>
      )}
    </>
  );
};

export default EventDetailPage;
