import { Helmet } from "react-helmet";

const MetaData = (props) => {
  const { title, keywords, description, image } = props;

  return (
    <Helmet>
      <title>{title ? `${title} | Tereda` : "Tereda"}</title>
      {keywords && <meta name="keywords" content={keywords} />}
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image} />}
      <meta name="og:locale" content="en_US" />
      <meta name="og:title" content={title ? `${title} | Tereda` : "Tereda"} />
      {description && <meta name="og:description" content={description} />}
      {image && <meta name="og:image" content={image} />}
      <meta name="og:url" content={window.location.href.split("?")[0]} />
      <meta name="og:site_name" content="Tereda" />
      <meta
        name="twitter:title"
        content={title ? `${title} | Tereda` : "Tereda"}
      />
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:url" content={window.location.href.split("?")[0]} />
      {props.children}
    </Helmet>
  );
};

export default MetaData;
