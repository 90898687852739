import GET_DATA from "../util/dataRequest";

export const getPageInfo = async (pageId) => {
  return GET_DATA({
    endpoint: pageId,
    params: { populate: "*" },
  });
};

export const getGeneralConfig = async () => {
  return GET_DATA({ endpoint: "general-settings", params: { populate: "*" } });
};
