import Member from "./Member";

const Members = (props) => {
  const { artistsData, addItemList } = props;

  let itemListJson = addItemList
    ? {
        "@context": "https://schema.org",
        "@id": window.location.origin + "#Members",
        "@type": "ItemList",
        itemListElement: [],
      }
    : null;

  if (addItemList) {
    artistsData.map((artist, index) => {
      itemListJson.itemListElement.push({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id": window.location.origin + "#Person" + artist?.attributes?.url,
        },
      });
    });
  }

  return (
    <>
      {addItemList && (
        <script type="application/ld+json">
          {JSON.stringify(itemListJson)}
        </script>
      )}
      {artistsData &&
        artistsData.map((artist) => {
          return <Member artistData={artist} key={artist?.attributes?.url} />;
        })}
    </>
  );
};

export default Members;
