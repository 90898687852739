import config from "../../../config/site";
import ArticleItem from "./ArticleItem";
import ElementAnimation from "../Utils/ElementAnimation";

const ArticlesList = (props) => {
  const { articlesListData, key } = props;

  return (
    <div key={key}>
      {articlesListData.length ? (
        <ul className={`grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mb-4`}>
          {articlesListData &&
            articlesListData.map((article, index) => (
              <li className={`mr-4 ml-4 md:pb-4`} key={index}>
                <ElementAnimation
                  animation="fadeInUp"
                  delay={200 + index * 100}
                >
                  <ArticleItem
                    title={article?.attributes?.title}
                    thumbnailImg={
                      config.backend_url +
                      article?.attributes?.thumbnail?.data?.attributes?.formats
                        ?.medium?.url
                    }
                    excerpt={article?.attributes?.excerpt}
                    articleLink={article?.attributes?.url}
                  />
                </ElementAnimation>
              </li>
            ))}
        </ul>
      ) : (
        <ElementAnimation animation="fadeIn" delay={300}>
          <p>There are currently no posts to display.</p>
        </ElementAnimation>
      )}
    </div>
  );
};

export default ArticlesList;
