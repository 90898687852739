const ErrorScreen = () => {
  return (
    <div
      className={`z-[1] w-full h-full absolute bg-white/70 table backdrop-blur-xl`}
    >
      <div className={`align-middle table-cell text-center`}>
        <h1 className={`text-4xl secondary-font`}>We're sorry...</h1>
        <h2 className={`mt-[60px] secondary-font`}>
          Looks like something didn't work out well...
        </h2>
        <h3 className={`mt-[20px] secondary-font`}>
          Please try reloading the page or try again later!
        </h3>
      </div>
    </div>
  );
};

export default ErrorScreen;
