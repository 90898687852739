import GET_DATA from "../util/dataRequest";

export const getMusicFeedSets = async (page, pageSize) => {
  return GET_DATA({
    endpoint: "music-feed-sets",
    params: {
      populate: "*",
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
    },
  });
};
