import { Link } from "react-router-dom";
import ElementAnimation from "./Utils/ElementAnimation";

const Section = (props) => {
  const {
    title,
    isMainTitle,
    contentBefore,
    inverted,
    textNotCentered,
    link,
    linkText,
    withPadding = true,
    isSecondaryFont = false,
    titleSm = false,
  } = props;

  let titleTag = isMainTitle ? (
    <h1
      className={`${inverted ? "text-black" : "text-white"} title-stroke ml-1 ${
        isSecondaryFont ? "secondary-font" : ""
      } ${titleSm ? "sm:text-5xl text-3xl mb-4" : "sm:text-7xl text-4xl"}`}
    >
      {title}
    </h1>
  ) : (
    <h2
      className={`${inverted ? "text-black" : "text-white"} title-stroke ml-1 ${
        isSecondaryFont ? "secondary-font" : ""
      } ${titleSm ? "sm:text-5xl text-3xl mb-4" : "sm:text-7xl text-4xl"}`}
    >
      {title}
    </h2>
  );

  return (
    <div
      className={`max-w-full overflow-hidden w-[100vw] ${
        withPadding ? "pt-6 pb-6" : ""
      } ${inverted ? "bg-black" : "bg-white"}${
        props?.className ? " " + props.className : ""
      }`}
      id={props?.id}
    >
      <div className={`max-w-[1140px] ml-auto mr-auto`}>
        {title && (
          <ElementAnimation animation="fadeIn" delay={100}>
            {titleTag}
          </ElementAnimation>
        )}
        {contentBefore && (
          <ElementAnimation animation="fadeIn" delay={200}>
            <div
              className={`${
                textNotCentered ? "" : "text-center"
              } px-2 z-10 whitespace-pre-wrap ${
                inverted ? "text-white" : "text-black"
              } pb-10`}
              dangerouslySetInnerHTML={{ __html: contentBefore }}
            />
          </ElementAnimation>
        )}
        {props.children && (
          <div className={`${inverted ? "text-white" : "text-black"} z-10`}>
            {props.children}
          </div>
        )}
      </div>
      {link && (
        <div className={`w-full`}>
          <Link
            className={`block text-center underline ${
              inverted ? "text-white" : "text-black"
            }`}
            to={link}
          >
            <ElementAnimation animation="fadeIn" delay={300}>
              <span>{linkText ? linkText : "Learn more"} ></span>
            </ElementAnimation>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Section;
