import { Link } from "react-router-dom";
import ElementAnimation from "./ElementAnimation";

const LinkButton = (props) => {
  const { inverted, centered = true, targetBlank = false } = props;

  return (
    <ElementAnimation animation="fadeIn" delay={300}>
      {props?.link?.charAt(0) === "#" ? (
        <a
          href={props.link}
          target={targetBlank ? "_blank" : "_self"}
          className={props.className}
        >
          <div
            className={`${
              inverted ? "bg-white text-black" : "bg-black text-white"
            } md:w-[70%] w-[95%] ${
              centered ? "mx-auto" : ""
            } px-10 text-3xl text-center py-2`}
          >
            <h3 className={`m-0 secondary-font`}>{props.title}</h3>
          </div>
        </a>
      ) : (
        <Link
          to={props.link}
          target={targetBlank ? "_blank" : "_self"}
          className={props.className}
        >
          <div
            className={`${
              inverted ? "bg-white text-black" : "bg-black text-white"
            } w-[100%] ${centered ? "mx-auto" : ""} text-3xl text-center py-2`}
          >
            <h3 className={`m-0 secondary-font`}>{props.title}</h3>
          </div>
        </Link>
      )}
    </ElementAnimation>
  );
};

export default LinkButton;
