import Section from "../Section";
import LinkButton from "../Utils/LinkButton";
import { useQuery } from "react-query";
import { getGeneralConfig } from "../../../requests/PageData";
import ErrorScreen from "../Utils/ErrorScreen";
import LoadingScreen from "../Utils/LoadingScreen";
import ElementAnimation from "../Utils/ElementAnimation";

const MusicFeedSection = (props) => {
  const { data, isError, isLoading } = useQuery([], () => getGeneralConfig());

  if (isError) {
    return <ErrorScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const musicFeedSettings = data?.data?.attributes?.musicFeed;

  return (
    <>
      {musicFeedSettings && (
        <Section
          title={musicFeedSettings.title}
          contentBefore={musicFeedSettings.content}
          inverted={false}
        >
          <div className={`md:w-[70%] w-[95%] mx-auto`}>
            <ElementAnimation animation="fadeIn" delay={300}>
              <LinkButton
                link="/music-feed"
                title={musicFeedSettings.buttonTitle}
              />
            </ElementAnimation>
          </div>
        </Section>
      )}
    </>
  );
};

export default MusicFeedSection;
