import Section from "../PageElements/Section";
import { useParams } from "react-router-dom";
import ContactUsButton from "../PageElements/Utils/ContactUsButton";
import MailChimpForm from "../PageElements/MailChimp/MailChimpForm";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import MetaData from "../PageElements/Utils/MetaData";
import config from "../../config/site";
import { useQuery } from "react-query";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import { getPostDetailInfo } from "../../requests/Blog";
import ContentSection from "../PageElements/Utils/ContentSection";
import WebPage from "../PageElements/RichSnippet/WebPage";
import BlogPosting from "../PageElements/RichSnippet/BlogPosting";

const ArticleDetailPage = (props) => {
  const { articleId } = useParams();
  const { data, isError, isLoading } = useQuery(
    ["article-detail", articleId],
    () => getPostDetailInfo(articleId),
  );

  if (isError) {
    return <ErrorScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const articleData = data?.data[0];

  return (
    <>
      {articleData && (
        <MetaData
          title={articleData?.attributes?.title}
          keywords={articleData?.attributes?.meta?.metaKeywords}
          description={articleData?.attributes?.meta?.metaDescription}
          image={
            config.backend_url +
            articleData.attributes.thumbnail.data.attributes.formats.medium.url
          }
        />
      )}{" "}
      {articleData && (
        <>
          <WebPage
            mainEntityId={
              window.location.origin +
              "/" +
              articleData.attributes.url +
              "#BlogPosting"
            }
            title={articleData.attributes.title}
            description={articleData?.attributes?.content}
          />
          <BlogPosting
            article={articleData.attributes}
            key={articleData.attributes.url}
          />
        </>
      )}
      {articleData && (
        <div>
          <Section withPadding={false}>
            <div className={`grid md:grid-cols-3 grid-cols-1`}>
              <div>
                <img
                  src={
                    config.backend_url +
                    articleData.attributes.thumbnail.data.attributes.formats
                      .medium.url
                  }
                  alt={articleData.attributes.title}
                />
              </div>
              <div className={`col-span-2`}>
                <Section
                  className={`text-left md:px-8 px-4`}
                  textNotCentered={true}
                  title={articleData.attributes.title}
                  isSecondaryFont={true}
                  titleSm={true}
                >
                  <div className={`mt-5`}>
                    <p>{articleData.attributes.excerpt}</p>
                  </div>
                </Section>
              </div>
            </div>
          </Section>
          {articleData.attributes.content.length > 0 && (
            <Section withPadding={false}>
              {articleData.attributes.content.map((section) => (
                <ContentSection sectionData={section} key={section.id} />
              ))}
            </Section>
          )}

          <MusicFeedSection />
          <MailChimpForm />
          <ContactUsButton />
        </div>
      )}
    </>
  );
};

export default ArticleDetailPage;
