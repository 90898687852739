import Section from "../PageElements/Section";
import { Link } from "react-router-dom";
import MetaData from "../PageElements/Utils/MetaData";
import logo from "../../assets/logo.webp";
import config from "../../config/site";
import { getArtistsBasicInfo } from "../../requests/Artists";
import { useQuery } from "react-query";
import { getPageInfo } from "../../requests/PageData";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";
import React from "react";
import Members from "../PageElements/RichSnippet/Members";
import CollectionPage from "../PageElements/RichSnippet/CollectionPage";

const pageId = "artists-list-page";

const ArtistListPage = (props) => {
  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  const {
    data: artistDataRaw,
    isError: artistQueryIsError,
    isLoading: artistQueryIsLoading,
  } = useQuery(["artists-basic-list"], () => getArtistsBasicInfo(), {
    keepPreviousData: true,
  });

  if (isPageDataError || artistQueryIsError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading || artistQueryIsLoading) {
    return <LoadingScreen />;
  }

  const pageData = pageDataRaw?.data;
  const artistData = artistDataRaw?.data;

  return (
    <>
      {pageData && (
        <MetaData
          title="Artists"
          keywords={pageData.attributes?.metaKeywords}
          description={pageData.attributes?.metaDescription}
          image={logo}
        />
      )}
      {artistData && (
        <>
          <CollectionPage
            mainEntityId={window.location.origin + "#Members"}
            title={artistData?.attributes?.title}
            altName={artistData?.attributes?.title}
            description={pageData.attributes?.content}
          />
          <Members artistsData={artistData} addItemList={true}></Members>
        </>
      )}
      <div>
        {pageData && pageData.attributes?.title && (
          <Section
            title={pageData.attributes?.title}
            contentBefore={pageData.attributes?.content}
            isMainTitle={true}
            inverted={true}
          />
        )}
        <Section inverted={false} withPadding={false}>
          <div className={`grid md:grid-cols-2 grid-cols-1 mb-4`}>
            {artistData &&
              artistData.length > 0 &&
              artistData.map((artist, index) => (
                <ElementAnimation animation="fadeInUp" delay={200 + index * 50}>
                  <div
                    className={`grid grid-cols-3`}
                    key={artist.attributes.url}
                  >
                    <div className={`col-span-1 bg-black flex items-center`}>
                      <Link to={`/artist/` + artist.attributes.url}>
                        <img
                          loading="lazy"
                          src={
                            config.backend_url +
                            artist.attributes.image.data.attributes.formats
                              .small.url
                          }
                          alt={artist.attributes.name}
                        />
                      </Link>
                    </div>
                    <div className={`col-span-2 sm:p-5 p-3 relative`}>
                      <Link to={`/artist/` + artist.attributes.url}>
                        <h2 className={`secondary-font sm:text-5xl text-3xl`}>
                          {artist.attributes.name}
                        </h2>
                      </Link>
                      <h3 className={`secondary-font text-xl sm:mt-3`}>
                        {artist.attributes.jobTitle}
                      </h3>
                      <div
                        className={`w-full align-bottom sm:absolute bottom-5 left-5`}
                      >
                        <Link
                          className={`underline`}
                          to={`/artist/` + artist.attributes.url}
                        >
                          <span className={`text-md`}>View Profile ></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </ElementAnimation>
              ))}
          </div>
        </Section>
        <MusicFeedSection />
      </div>
    </>
  );
};

export default ArtistListPage;
