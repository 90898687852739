import Section from "../Section";
import config from "../../../config/site";
import ElementAnimation from "./ElementAnimation";

const ContentSection = (props) => {
  const { sectionData } = props;

  return (
    <Section inverted={sectionData?.invertedColor} withPadding={false}>
      <div className={`sm:flex grid`}>
        {sectionData?.image?.data && (
          <div
            className={`${
              sectionData?.imgRight ? "sm:order-3" : "sm:order-1"
            } sm:max-w-[33%] sm:min-w-[33%] min-w-full order-3`}
          >
            <ElementAnimation animation="fadeIn" delay={300}>
              <img
                loading="lazy"
                src={
                  config.backend_url +
                  sectionData?.image.data?.attributes?.formats.medium.url
                }
                alt={
                  sectionData?.imgAltText
                    ? sectionData?.imgAltText
                    : sectionData?.id
                }
              />
            </ElementAnimation>
          </div>
        )}
        {sectionData?.text && (
          <div className={`m-4 order-2 flex items-center`}>
            <ElementAnimation animation="fadeIn" delay={300}>
              {sectionData?.title && (
                <h2 className={`secondary-font text-4xl`}>
                  {sectionData.title}
                </h2>
              )}
              <p
                className={sectionData?.textCentered ? `text-center` : ``}
                dangerouslySetInnerHTML={{ __html: sectionData.text }}
              />
            </ElementAnimation>
          </div>
        )}
      </div>
    </Section>
  );
};

export default ContentSection;
