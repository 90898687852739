import { Link } from "react-router-dom";
import config from "../../../config/site";

const SetsList = (props) => {
  const { musicFeedData } = props;

  const img =
    config.backend_url +
    musicFeedData[0]?.attributes?.artwork?.data?.attributes?.formats?.thumbnail
      ?.url;

  return (
    <div className={`grid md:grid-cols-2 grid-cols-1 mb-4`}>
      {musicFeedData &&
        musicFeedData.map((musicFeedItem) => (
          <Link
            to={musicFeedItem?.attributes?.link}
            target={"_blank"}
            aria-label={musicFeedItem?.attributes?.title}
            key={musicFeedItem?.attributes?.link}
          >
            <div
              className={`grid grid-cols-7 min-h-[100px] h-full min-h-[150px]`}
            >
              <div className={`col-span-2 relative`}>
                <div
                  className={`w-full h-full bg-cover bg-no-repeat bg-center`}
                  style={{
                    backgroundImage: `url('${
                      config.backend_url +
                      musicFeedItem?.attributes?.artwork?.data?.attributes
                        ?.formats?.thumbnail?.url
                    }')`,
                  }}
                ></div>
                <div
                  className={`absolute w-full h-full top-0 bottom-0 bg-gradient-to-r from-transparent from-750% to-black`}
                ></div>
              </div>
              <div
                className={`table col-span-5 text-right px-2 relative bg-black text-white py-4`}
              >
                <h2 className={`block ml-[-120px] text-lg`}>
                  {musicFeedItem?.attributes?.title}
                </h2>
                <span
                  className={`table-row block secondary-font text-gray-500 text-lg`}
                >
                  By {musicFeedItem?.attributes?.madeBy}
                </span>
                <span className={`table-cell align-bottom secondary-font`}>
                  <i
                    className={`${musicFeedItem?.attributes?.platform}-icon inline-block align-middle w-[20px] h-[20px] mr-2`}
                  />
                  <span className={`relative bottom-[-1.5px]`}>
                    {musicFeedItem?.attributes?.platform}
                  </span>
                </span>
              </div>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default SetsList;
