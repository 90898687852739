import { Link } from "react-router-dom";

const Event = (props) => {
  const { name, image, date, location, locationLink, eventLink, labels } =
    props;

  return (
    <div className={`max-w-full shadow-lg my-2`}>
      <div
        className={`relative aspect-[1.91/1] overflow-hidden flex items-center`}
      >
        <Link className={`w-full`} to={`/event/` + eventLink}>
          <img
            loading="lazy"
            src={image}
            alt={name}
            width="600px"
            height="262px"
            className={`w-full`}
          />
        </Link>
        <div className={`absolute right-0 top-0`}>
          {labels.map((label) => (
            <div
              className={`inline-block px-2 text-white ml-2`}
              style={{ backgroundColor: label.color }}
              key={label.title}
            >
              <span className={`secondary-font`}>{label.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={`px-2 pt-3 pb-1 relative bg-white`}>
        <Link to={`/event/` + eventLink}>
          <h3 className={`sm:text-3xl text-2xl secondary-font`}>{name}</h3>
        </Link>
        <ul className={`text-sm`}>
          <li className={`pt-1`}>
            <i
              className={`date-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
            />
            {new Date(date).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </li>
          <li className={`pt-1`}>
            <i
              className={`location-icon inline-flex w-[20px] h-[20px] -mb-[5px] mr-2`}
            />
            {locationLink ? (
              <Link to={locationLink} target="_blank" aria-label={location}>
                {location}
              </Link>
            ) : (
              location
            )}
          </li>
        </ul>
        <Link
          to={`/event/` + eventLink}
          className={`text-right block mt-2 mb-0`}
        >
          <span className={`text-md underline underline-offset-1`}>
            Learn More >
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Event;
