const Pagination = (props) => {
  const { page, pageCount, onPageChange, rootPath, paginatedPath } = props;

  let pageNumbers = [];

  const pageNumberClickHandler = (pageNr) => {
    onPageChange(pageNr);

    if (pageNr === 1) {
      window.history.pushState(null, "", rootPath);
    } else {
      window.history.pushState(null, "", paginatedPath + pageNr);
    }
  };

  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(
      <li
        className={`float float-left px-3 py-2 ${
          i === page ? "bg-black text-white" : "cursor-pointer"
        }`}
        onClick={() => pageNumberClickHandler(i)}
        key={i}
      >
        {i}
      </li>,
    );
  }

  return (
    <div className={`w-full text-center`}>
      <ul className={`inline-block`}>{pageNumbers}</ul>
    </div>
  );
};

export default Pagination;
