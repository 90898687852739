const CollectionPage = (props) => {
  const { mainEntityId, title, altName, description } = props;

  let descriptionText = "";

  if (typeof description === "object") {
    description.map((description) => {
      if (typeof description.text !== "undefined") {
        descriptionText += description.text;
      }
    });
  } else {
    descriptionText = description;
  }

  let jsonContent = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "@id": window.location.href + "#CollectionPage",
    url: window.location.href,
    name: title,
    alternateName: altName,
    description: descriptionText,
    accessMode: ["textual", "visual"],
    isFamilyFriendly: true,
    inLanguage: {
      "@type": "Language",
      name: "en",
      alternateName: "English",
      description: "English",
    },
    headline: title,
    text: descriptionText,
    reviewedBy: {
      "@id": window.location.origin + "#Organization",
    },
    isPartOf: {
      "@id": window.location.origin + "#WebSite",
    },
    mainEntity: {
      "@id": mainEntityId,
    },
  };
  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default CollectionPage;
