import BlogPosting from "./BlogPosting";
import { useQuery } from "react-query";
import { getPageInfo } from "../../../requests/PageData";

const Blog = (props) => {
  const { articles, isPartOf = null } = props;

  const { data: pageDataRaw } = useQuery(["page-info", "blog-page"], () =>
    getPageInfo("blog-page"),
  );

  const pageData = pageDataRaw?.data;

  let jsonContent = {
    "@context": "https://schema.org/",
    "@type": "Blog",
    "@id": window.location.href + "#Blog",
    name: pageData?.attributes?.title,
    description: pageData?.attributes?.metaDescription,
    publisher: {
      "@id": window.location.origin + "#Organization",
    },
    blogPost: [],
  };

  if (isPartOf) {
    jsonContent.isPartOf = isPartOf;
  }

  let blogPostingsJson = articles.map(function (article) {
    jsonContent.blogPost.push({
      "@id":
        window.location.origin +
        "/" +
        article?.attributes?.url +
        "#BlogPosting",
    });

    return (
      <BlogPosting article={article.attributes} key={article.attributes.url} />
    );
  });

  return (
    <>
      {blogPostingsJson && blogPostingsJson}
      <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
    </>
  );
};

export default Blog;
