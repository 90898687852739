import classes from "./Homepage.module.css";
import logo from "../../assets/logo.webp";
import logoMobile from "../../assets/logo-mobile.webp";
import Section from "../PageElements/Section";
import EventsSlider from "../PageElements/Events/EventsSlider";
import ArtistsShowcase from "../PageElements/ArtistsShowcase";
import ArticlesSlider from "../PageElements/Blog/ArticlesSlider";
import ContactForm from "../PageElements/Contact/ContactForm";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import MetaData from "../PageElements/Utils/MetaData";
import { useQuery } from "react-query";
import { getGeneralConfig, getPageInfo } from "../../requests/PageData";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import WebPage from "../PageElements/RichSnippet/WebPage";

const pageId = "home-page";

const HomePage = (props) => {
  const { data, isError, isLoading } = useQuery(["page-info", pageId], () =>
    getPageInfo(pageId),
  );

  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isError || isConfigError) {
    return <ErrorScreen />;
  }

  if (isLoading || isConfigLoading) {
    return <LoadingScreen />;
  }

  const pageData = data?.data?.attributes;

  const configData = configDataRaw?.data?.attributes;

  return (
    <>
      {pageData && (
        <MetaData
          title="Home"
          keywords={pageData?.meta?.metaKeywords}
          description={pageData?.meta?.metaDescription}
          image={logo}
        >
          <link
            rel="preload"
            as="image"
            href={logo}
            imageSrcSet={logoMobile + " 350w, " + logo + " 575w"}
            imageSizes="(min-width: 640px) 575px,350px"
          />
        </MetaData>
      )}
      <WebPage
        mainEntityId={window.location.origin + "#Organization"}
        title="Tereda"
        description={pageData?.meta?.metaDescription}
      />
      <div className={`h-[100vh] ${classes.topContainer}`}>
        <div className={classes.topContainerImg}>
          <div className={`w-full flex-auto`}>
            <div className={`w-full absolute top-1/3`}>
              <img
                className={`ml-auto mr-auto relative block sm:max-w-[575px] max-w-[350px] w-[70vw]`}
                fetchpriority="high"
                width="575px"
                height="244px"
                srcSet={logoMobile + " 350w, " + logo + " 575w"}
                sizes="(min-width: 640px) 575px,350px"
                alt="Logo"
              />
            </div>
            <div className={classes.mouseIcon}>
              <div className={classes.wheel}></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {configData?.aboutUs && (
          <Section
            title={configData.aboutUs.title}
            contentBefore={configData.aboutUs.content}
            link={`/about-us`}
            linkText={configData.aboutUs?.aboutUsLinkTitle}
            inverted={true}
          />
        )}
        {configData?.eventsSlider && (
          <EventsSlider
            onlyUpcoming={true}
            limit={configData.eventsSlider.limit}
          />
        )}
        <ArtistsShowcase />
        <MusicFeedSection />
        {configData?.blogSlider && (
          <ArticlesSlider limit={configData.blogSlider.limit} />
        )}
        <Section title={"Contact Us"} inverted={true}>
          <ContactForm />
        </Section>
      </div>
    </>
  );
};

export default HomePage;
