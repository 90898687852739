const WebSite = (props) => {
  const jsonContent = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": window.location.origin + "#WebSite",
    url: window.location.origin,
    name: "Tereda",
    alternateName: "tereda.events",
    description: "Tereda Events Website",
    accessMode: ["textual", "visual"],
    isAccessibleForFree: true,
    isFamilyFriendly: true,
    publisher: {
      "@id": window.location.origin + "#Organization",
    },
    inLanguage: {
      "@type": "Language",
      name: "en",
      alternateName: "English",
      description: "English",
    },
  };

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default WebSite;
