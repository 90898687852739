const WebPage = (props) => {
  const { mainEntityId, title, description } = props;

  let descriptionText = "";

  if (typeof description === "object") {
    description.map((description) => {
      if (typeof description.text !== "undefined") {
        descriptionText += description.text;
      }
    });
  } else {
    descriptionText = description;
  }

  const jsonContent = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": window.location.href + "#WebPage",
    url: window.location.href,
    name: title,
    alternateName: "tereda.events",
    description: descriptionText,
    accessMode: ["textual", "visual"],
    isAccessibleForFree: true,
    isFamilyFriendly: true,
    publisher: { "@id": window.location.origin + "#Organization" },
    inLanguage: {
      "@type": "Language",
      name: "en",
      alternateName: "English",
      description: "English",
    },
    mainEntity: mainEntityId,
    isPartOf: window.location.origin + "#WebSite",
  };

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default WebPage;
