import HomePage from "../components/Pages/HomePage";
import EventListPage from "../components/Pages/EventListPage";
import EventDetailPage from "../components/Pages/EventDetailPage";
import ArtistListPage from "../components/Pages/ArtistListPage";
import ArtistDetailPage from "../components/Pages/ArtistDetailPage";
import CommunityPage from "../components/Pages/CommunityPage";
import MusicFeedPage from "../components/Pages/MusicFeedPage";
import ContactPage from "../components/Pages/ContactPage";
import ArticleListPage from "../components/Pages/ArticleListPage";
import ArticleDetailPage from "../components/Pages/ArticleDetailPage";
import SimplePage from "../components/Pages/SimplePage";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about-us",
    element: <HomePage />,
  },
  {
    path: "/events",
    element: <EventListPage limit={5} />,
  },
  {
    path: "/events/page/:pageNr",
    element: <EventListPage limit={5} />,
  },
  {
    path: "/event/:eventId",
    element: <EventDetailPage />,
  },
  {
    path: "/artists",
    element: <ArtistListPage limit={8} />,
  },
  {
    path: "/artist/:artistUrl",
    element: <ArtistDetailPage />,
  },
  {
    path: "/articles",
    element: <ArticleListPage limit={6} />,
  },
  {
    path: "/articles/page/:pageNr",
    element: <ArticleListPage limit={6} />,
  },
  {
    path: "/article/:articleId",
    element: <ArticleDetailPage />,
  },
  {
    path: "/community",
    element: <CommunityPage />,
  },
  { path: "/music-feed", element: <MusicFeedPage limit={10} /> },
  {
    path: "/music-feed/page/:pageNr",
    element: <MusicFeedPage limit={10} />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <SimplePage pageId="terms-and-conditions" />,
  },
  {
    path: "/privacy-policy",
    element: <SimplePage pageId="privacy-policy" />,
  },
];

export default routes;
