import logo from "../../../assets/logo.webp";

const LoadingScreen = () => {
  return (
    <div
      className={`z-[1000] w-full h-full absolute bg-white/70 table backdrop-blur-xl`}
    >
      <div className={`align-middle table-cell`}>
        <svg
          className="animate-spin h-10 w-10 ml-auto mr-auto"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <h1 className={`text-center mt-[20px] secondary-font text-2xl`}>
          Loading...
        </h1>
      </div>
    </div>
  );
};

export default LoadingScreen;
