import logo from "../../assets/logo.webp";
import Section from "../PageElements/Section";
import SetsList from "../PageElements/MusicFeed/SetsList";
import { useState } from "react";
import MetaData from "../PageElements/Utils/MetaData";
import { useParams } from "react-router-dom";
import Pagination from "../PageElements/Utils/Pagination";
import React from "react";
import { getMusicFeedSets } from "../../requests/MusicFeedSets";
import { useQuery } from "react-query";
import { getPageInfo } from "../../requests/PageData";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";

const pageId = "music-feed-page";

const MusicFeedPage = (props) => {
  const { pageNr } = useParams();
  const [currentPage, setCurrentPage] = useState(pageNr ? pageNr : 1);

  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  const {
    data,
    isError: isMusicFeedListError,
    isLoading: isMusicFeedListLoading,
  } = useQuery(
    ["music-feed-list", currentPage, props.limit],
    () => getMusicFeedSets(currentPage, props.limit),
    {
      keepPreviousData: true,
    },
  );

  if (isPageDataError || isMusicFeedListError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading || isMusicFeedListLoading) {
    return <LoadingScreen />;
  }

  const pageData = pageDataRaw?.data;
  const musicFeedData = data?.data;
  const paginationData = data?.meta.pagination;

  return (
    <>
      {pageData && (
        <MetaData
          title="Music Feed"
          keywords={pageData.attributes?.metaKeywords}
          description={pageData.attributes?.metaDescription}
          image={logo}
        />
      )}
      {pageData && pageData.attributes?.title && (
        <Section
          title={pageData.attributes.title}
          contentBefore={pageData.attributes.content}
          inverted={true}
        />
      )}
      <Section>
        {musicFeedData && <SetsList musicFeedData={musicFeedData}></SetsList>}
        {paginationData && paginationData.pageCount > 1 && (
          <Pagination
            page={paginationData.page}
            pageCount={paginationData.pageCount}
            onPageChange={setCurrentPage}
            rootPath={"/music-feed"}
            paginatedPath={"/music-feed/page/"}
          />
        )}
      </Section>
    </>
  );
};

export default MusicFeedPage;
