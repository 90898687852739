import logo from "../../../assets/logo.webp";

const Organization = (props) => {
  const { contactInfo, artistsData } = props;

  let jsonContent = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": window.location.origin + "#Organization",
    url: window.location.origin,
    name: "Tereda",
    alternateName: "tereda.events",
    logo: window.location.origin + logo,
    email: contactInfo?.email,
    event: { "@id": window.location.origin + "#Events" },
    foundingDate: 2022,
    foundingLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: {
          "@type": "Country",
          name: "HU",
        },
        addressLocality: "Budapest",
        addressRegion: "Budapest",
      },
    },
    location: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: {
          "@type": "Country",
          name: "HU",
        },
        addressLocality: "Budapest",
        addressRegion: "Budapest",
      },
    },
    knowsAbout: "Micro- and minimal house event organizer in Budapest",
    member: [],
    description: "",
    sameAs: [
      contactInfo?.facebook,
      contactInfo?.instagram,
      contactInfo?.soundcloud,
      contactInfo?.youtube,
    ],
    contactPoint: {
      "@type": "ContactPoint",
      email: contactInfo?.email,
      contactType: "Contact Email Address",
    },
    address: {
      "@type": "PostalAddress",
      addressCountry: {
        "@type": "Country",
        name: "HU",
      },
    },
  };

  if (artistsData !== undefined && artistsData?.length > 0) {
    jsonContent.member = artistsData.map((artist) => {
      return {
        "@id": window.location.origin + "#Person" + artist?.attributes?.url,
      };
    });
  }

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default Organization;
