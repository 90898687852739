import Section from "../PageElements/Section";
import LinkButton from "../PageElements/Utils/LinkButton";
import MetaData from "../PageElements/Utils/MetaData";
import logo from "../../assets/logo.webp";
import { getPageInfo } from "../../requests/PageData";
import { useQuery } from "react-query";
import ErrorScreen from "../PageElements/Utils/ErrorScreen";
import LoadingScreen from "../PageElements/Utils/LoadingScreen";
import ContentSection from "../PageElements/Utils/ContentSection";
import MusicFeedSection from "../PageElements/MusicFeed/MusicFeedSection";
import ElementAnimation from "../PageElements/Utils/ElementAnimation";
import WebPage from "../PageElements/RichSnippet/WebPage";
import React from "react";

const pageId = "community-page";

const CommunityPage = (props) => {
  const {
    data: pageDataRaw,
    isError: isPageDataError,
    isLoading: isPageDataLoading,
  } = useQuery(["page-info", pageId], () => getPageInfo(pageId));

  if (isPageDataError) {
    return <ErrorScreen />;
  }

  if (isPageDataLoading) {
    return <LoadingScreen />;
  }

  const pageData = pageDataRaw?.data;

  return (
    <>
      {pageData && (
        <MetaData
          title={pageData?.attributes?.title}
          keywords={pageData?.attributes?.metaKeywords}
          description={pageData?.attributes?.metaDescription}
          image={logo}
        />
      )}
      {pageData && (
        <WebPage
          title={pageData?.attributes?.title}
          description={pageData.attributes?.content}
        />
      )}
      <div>
        {pageData && pageData.attributes?.title && (
          <Section title={pageData.attributes.title} inverted={true} />
        )}
        {pageData?.attributes?.content?.length > 0 && (
          <Section withPadding={false}>
            {pageData.attributes.content.map((section) => (
              <ContentSection sectionData={section} key={section.id} />
            ))}
          </Section>
        )}
        {pageData && pageData?.attributes?.joinUsButtonLink && (
          <Section inverted={false}>
            <ElementAnimation animation="fadeIn" delay={300}>
              <div className={`md:w-[70%] w-[95%] mx-auto`}>
                <LinkButton
                  link={pageData.attributes.joinUsButtonLink}
                  title={pageData.attributes.joinButtonTitle}
                />
              </div>
            </ElementAnimation>
          </Section>
        )}
        <MusicFeedSection />
      </div>
    </>
  );
};

export default CommunityPage;
