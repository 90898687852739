const Performer = (props) => {
  const artistData = props.artistData;

  let jsonContent = {
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": window.location.origin + "#Person" + artistData.name,
    name: artistData.name,
    description: artistData.description,
    image: artistData.image,
    jobTitle: "DJ",
    address: {
      "@type": "PostalAddress",
      addressCountry: {
        "@type": "Country",
        name: artistData.country,
      },
    },
    url: artistData.link,
    sameAs: artistData.alternativeLinks,
  };

  if (
    artistData.links !== undefined &&
    artistData.links.split(",").length > 0
  ) {
    jsonContent.sameAs.concat(artistData.links.split(","));
  }

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default Performer;
