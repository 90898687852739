import GET_DATA from "../util/dataRequest";

export const getPostsInfo = async (page, pageSize) => {
  return GET_DATA({
    endpoint: "posts",
    params: {
      populate: ["title", "thumbnail", "url", "excerpt", "meta"],
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
    },
  });
};

export const getPostDetailInfo = async (articleId) => {
  return GET_DATA({
    endpoint: "posts",
    params: {
      populate: [
        "title",
        "thumbnail",
        "url",
        "excerpt",
        "content",
        "content.image",
        "meta",
        "relatedEvents",
      ],
      "filters[url][$eq]": articleId,
      "pagination[pageSize]": 1,
      "pagination[page]": 1,
    },
  });
};
