import config from "../../../config/site";

const Member = (props) => {
  const artistData = props.artistData?.attributes;

  let jsonContent = {
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": window.location.origin + "#Person" + artistData.url,
    name: artistData.name,
    description: artistData.biography,
    image:
      config.backend_url +
      artistData.image?.data?.attributes?.formats?.medium?.url,
    affiliation: { "@id": window.location.origin + "#Organization" },
    brand: { "@id": window.location.origin + "#Organization" },
    email: artistData.socialLinks.email,
    jobTitle: artistData.jobTitle,
    address: {
      "@type": "PostalAddress",
      addressCountry: {
        "@type": "Country",
        name: "HU",
      },
    },
    memberOf: { "@id": window.location.origin + "#Organization" },
    url: window.location.origin + "/artist/" + artistData.url,
    sameAs: [
      artistData.socialLinks.facebook,
      artistData.socialLinks.instagram,
      artistData.socialLinks.soundcloud,
      artistData.socialLinks.youtube,
    ],
  };

  if (artistData.link !== undefined && artistData.link.length > 0) {
    jsonContent.sameAs = jsonContent.sameAs.concat(
      artistData.link.map((link) => {
        return link.link;
      }),
    );
  }

  return (
    <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
  );
};

export default Member;
