import config from "../../../config/site";
import Performer from "./Performer";

const Event = (props) => {
  const eventData = props.eventsData;

  let jsonContent = {
    "@context": "https://schema.org",
    "@type": "Event",
    "@id": window.location.origin + "#Event" + eventData.url,
    name: eventData.name,
    description: eventData.description,
    image:
      config.backend_url +
      eventData.coverArtwork.data.attributes.formats.medium.url,
    eventStatus: "EventScheduled",
    eventAttendanceMode: "OfflineEventAttendanceMode",
    startDate: eventData.eventDate.startDate,
    endDate: eventData.eventDate.endDate,
    doorTime: eventData.eventDate.startDate,
    isAccessibleForFree: "false",
    keywords: eventData.metaKeywords,
    location: {
      "@type": "Place",
      name: eventData.location.locationName,
      address: {
        "@type": "PostalAddress",
        addressCountry: {
          "@type": "Country",
          name: "HU",
        },
        addressLocality: eventData.location.location,
        addressRegion: eventData.location.region,
        streetAddress: eventData.location.address,
      },
    },
    organizer: eventData.selfHosted
      ? { "@id": window.location.origin + "#Organization" }
      : eventData.organizer,
    performer: [],
    url: window.location.origin,
    sameAs: eventData.fbLink,
  };

  let memberArtistIds = eventData?.artists?.data?.map((artist) => {
    return { "@id": "https://tereda.events/#Person" + artist?.attributes?.url };
  });

  jsonContent.performer = jsonContent.performer.concat(memberArtistIds);
  let performerData = [];

  if (eventData.artist.length > 0) {
    performerData = eventData.artist.map((artist) => {
      jsonContent.performer.push({
        "@id": "https://tereda.events/#Person" + artist.name,
      });

      return {
        name: artist.name,
        description: artist.description,
        country: artist.country,
        link: artist.mainLink,
        image:
          artist.image === undefined
            ? null
            : config.backend_url +
              artist.image?.data?.attributes?.formats?.medium?.url,
        alternativeLinks: artist.links?.split(","),
      };
    });
  }

  return (
    <>
      {performerData &&
        performerData.map((artist) => (
          <Performer artistData={artist} key={artist.name} />
        ))}
      <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
    </>
  );
};

export default Event;
