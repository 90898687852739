import ArticleItem from "../Blog/ArticleItem";
import { getPostsInfo } from "../../../requests/Blog";
import { Link } from "react-router-dom";
import config from "../../../config/site";
import { useQuery } from "react-query";
import ComponentLoadingOverlay from "../Utils/ComponentLoadingOverlay";
import Blog from "../RichSnippet/Blog";
import Section from "../Section";
import { getGeneralConfig } from "../../../requests/PageData";
import ElementAnimation from "../Utils/ElementAnimation";

const ArticlesSlider = (props) => {
  const { limit } = props;

  const { data, isError, isLoading } = useQuery(
    ["blog-posts-list", 1],
    () => getPostsInfo(1, limit),
    {
      keepPreviousData: true,
    },
  );

  const {
    data: configDataRaw,
    isError: isConfigError,
    isLoading: isConfigLoading,
  } = useQuery([], () => getGeneralConfig());

  if (isLoading || isConfigLoading) {
    return <ComponentLoadingOverlay />;
  }

  if (isError || isConfigError) {
    return <ComponentLoadingOverlay />;
  }

  const postsData = data?.data;
  const configData = configDataRaw?.data?.attributes?.blogSlider;

  return (
    <>
      {configData && (
        <Section
          title={configData.title}
          contentBefore={configData.content}
          inverted={true}
        >
          <Blog
            articles={postsData}
            isPartOf={window.location.origin + "#WebSite"}
          />
          <ul
            className={`flex md:flex-wrap flex-no-wrap overflow-x-scroll scrolling-touch items-start mb-4 scrollbar-hidden`}
          >
            {postsData &&
              postsData.map((article, index) => (
                <li
                  className={`flex-none w-[calc(75%-2rem)] md:flex lg:w-[calc(33.333333%-2rem)] md:w-[calc(50%-2rem)] sm:w-[calc(55%-2rem)] md:flex-wrap mr-4 ml-4 md:pb-4`}
                  key={article.attributes.url}
                >
                  <ElementAnimation
                    animation="fadeInUp"
                    delay={200 + index * 100}
                  >
                    <ArticleItem
                      title={article.attributes.title}
                      thumbnailImg={
                        config.backend_url +
                        article.attributes.thumbnail.data.attributes.formats
                          .small.url
                      }
                      excerpt={article.attributes.excerpt}
                      articleLink={article.attributes.url}
                    ></ArticleItem>
                  </ElementAnimation>
                </li>
              ))}
          </ul>
          <div className={`w-full`}>
            <ElementAnimation animation="fadeIn" delay={300}>
              <Link className={`block text-center underline`} to="/articles">
                <span>{configData.viewAllEventsLinkTitle} ></span>
              </Link>
            </ElementAnimation>
          </div>
        </Section>
      )}
    </>
  );
};

export default ArticlesSlider;
