import WebSite from "./RichSnippet/WebSite";
import Organization from "./RichSnippet/Organization";
import { useQuery } from "react-query";
import { getContactInfo } from "../../requests/Contact";
import { getArtistsBasicInfo } from "../../requests/Artists";
import Members from "./RichSnippet/Members";

const RichSnippets = (props) => {
  const {
    data: contactInfoRaw,
    isContactInfoError,
    isContactInfoLoading,
  } = useQuery(["contact-info"], () => getContactInfo());

  const {
    data: artistsRaw,
    artistsIsError,
    artistsIsLoading,
  } = useQuery(["artists-basic-list"], () => getArtistsBasicInfo());

  if (isContactInfoError || artistsIsError) {
    return "";
  }

  if (isContactInfoLoading || artistsIsLoading) {
    return "";
  }

  const artistsData = artistsRaw?.data;
  const contactInfo = contactInfoRaw?.data?.attributes;

  return (
    <>
      <WebSite />
      <Organization contactInfo={contactInfo} artistsData={artistsData} />
      <Members artistsData={artistsData} />
    </>
  );
};

export default RichSnippets;
